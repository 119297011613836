import "./App.css";
import  Main  from "./Main";
import { BrowserRouter as Router, Routes ,Route} from 'react-router-dom';
import PrivacyPolicy from "./componenets/privacypolicy";
function App() {
  return (
    <div className="App">
    <Router>
    <Routes>
      <Route path="/" element={<Main/>  } />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>  } />
    </Routes>
      </Router>
    </div>
  );
}
export default App;
