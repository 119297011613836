import React, { useState } from "react";
import "../styles/About.scss";
import AboutBG from "../assets/AboutBg.svg";

import { motion, AnimatePresence } from "framer-motion";

export const About = () => {
  const slideIn = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };
  const variants = {
    initial: {
      x: "-200%",
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
    },
    exit: {
      x: "100%",
      opacity: 0,
    },
  };

  const [isVisible, setIsVisible] = useState(true);

  return (
    <section className="about">
      <div className="about-deco-container"></div>

      <div className="about-container">
        {/* <div className="about-image-container"> */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="about-image-container"
        >
          <img className="aboutus-image" src={AboutBG} alt="" />
        </motion.div>

        {/* </div> */}
        <div className="about-description-container">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 1 }}
            variants={{
              hidden: { scale: 0.5 },
              visible: { scale: 1 },
            }}
          >
            <h1 className="about-header">Who we are</h1>
          </motion.div>
          <div className="about-descriptions-content">
            <p className="about-descriptions-text">
              Populus Empowerment Network (PEN) is a socio-political research
              organization committed to upholding democracy with a social
              scientific approach. Our advanced scientific methods and
              intelligent solutions enable us to conduct systematic and
              empirical studies of various aspects of politics, providing
              practical and evidence-based recommendations for better
              decision-making and informed policy-making.
            </p>
            <p className="about-descriptions-text">
              At PEN, we prioritize reliability and objectivity, making us a
              trusted partner for navigating the complex landscape of
              contemporary politics. Our commitment to using a social scientific
              approach ensures that our work is always grounded in rigorous and
              verifiable research methods.
            </p>
            <p className="about-descriptions-text">
              As a critical player in shaping the political discourse of the
              present and future, we are dedicated to building a brighter, more
              democratic future for all.
            </p>
          </div>
        </div>
        <div className="about-vertical-deco-container"></div>
        <div className="about-our-culture">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false }}
            transition={{ duration: 1 }}
            variants={{
              hidden: { scale: 0.5 },
              visible: { scale: 1 },
            }}
          >
            <h1 className="about-header-our-culture">Our culture</h1>
          </motion.div>
          <div className="about-descriptions-our-culture">
            <p className="about-descriptions-text">
              At PEN, we foster a culture of flexibility, openness, and
              collaboration that values purpose-driven work and positively
              impacts people’s lives. We prioritize cultural competency and
              diversity of thought, and our team embodies traits of teamwork,
              honesty, and problem-solving.
            </p>
            <p className="about-descriptions-text">
              We celebrate success and embrace challenges as opportunities for
              growth and development. Our core values guide us towards
              continuous growth and development in both our work and
              professional lives.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
