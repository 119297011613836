// import React from "react";
import "../styles/privacypolicy.scss";
import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy">
      <h1 className="title">Privacy Policy</h1>
      <p className="note">
        This Privacy Policy explains how we at Populus Empowerment Network
        collect, use, retain and disclose Personal Data collected from you on
        our website.
      </p>
      <h1 className="wwr">Who we are</h1>
      <p className="wwrc">
        Populus Empowerment Network (PEN) is a global solution provider
        specializing in data. We provide a SaaS platform helping businesses to
        increase employee engagement and motivate them through Gamification. We
        constantly update our technology to suit our customer’s needs. We are
        committed to protecting your personal information and your right to
        privacy.
      </p>
      <h1 className="info-col">Information Collection</h1>
      <p className="info-col-con1">
        We collect your Personal Data in the following ways on our website:
      </p>
      <h2 className="info-col-tl1">
        1. Information provided by you when you visit our website
      </h2>
      <div className="info-col-points">
        <p className="points-text">
          <span className="arrow">&#10148;</span>Personal information you
          disclose to us. Such as Name, Address, Email Address & Contact{" "}
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span>Information Information
          automatically collected. Such as IP address and/or browser and device
          characteristics – is collected automatically when you visit our
          Services.{" "}
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span> Subscribe by Email For and
          submit your Name and Email Address.
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span>As a paying user of our
          service, we may collect data related to your transaction such as name,
          the amount paid and the date of payment.
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span> Request an Enquiry Form and
          submit your Name, Phone, Email Address, and Company name.
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span> You connect to us on a call
          where we collect your Phone Number, Name and Email Address to contact
          you back.
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span>You connect to us by sending us
          emails where we collect your Name and Email Address to contact you
          back.
        </p>
        <p className="points-text">
          <span className="arrow">&#10148;</span>You submit your resume by
          emailing it to the specified email address.{" "}
        </p>
      </div>
      <h2 className="info-col-tl2">2. Through cookies on our website</h2>
      <p className="points-text">
        The cookies collect information about you when you visit a website. For
        more information about cookies, please refer to the ‘Cookies
        Information’ section on this Policy.
      </p>
      <p className="points-text">
        We retain and use your Personal Data as long as it is necessary for the
        fulfilment of the respective purposes as specified in this section.
      </p>
      <h1 className="info-col">Information Collection</h1>
      <h2 className="info-col-tl1">Personal Data</h2>
      <h2 className="info-col-ps">
        1. We process your information for purposes based on legitimate business
        interests, the fulfilment of our contract with you, compliance with our
        legal obligations, and/or your consent.
      </h2>
      <h2 className="info-col-ps">
        2. We may use your Personal Data to contact you with newsletters,
        marketing or promotional materials and other information that may be of
        interest to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or the
        instructions provided in any email we send
      </h2>
      <h2 className="info-col-ps">
        3. We use the collected Personal Data for: We retain and use your
        Personal Data as long as it is necessary for the fulfilment of the
        respective purposes as specified in this section.
      </h2>
      <div className="info-col-points-ps">
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span> Providing you with our
          Solutions and/or Services
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span>Contacting you for providing
          our Solutions and/or Services
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span> Answering your queries or
          technical issues or complaints
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span>Tracking your activities on our
          website
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span>Providing you with the
          Marketing Material in case you have opted for
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span>Sending you updates about our
          blog
        </p>
        <p className="points-text_ps">
          <span className="arrow">&#10148;</span>Recruitment purposes
        </p>
      </div>
      <h2 className="hl-tl">How long do we keep your informations?</h2>
      <p className="hl-con">
        We keep your information for as long as necessary to fulfil the purposes
        outlined in this privacy policy unless otherwise required by law. We
        will only keep your personal information for as long as it is necessary
        for the purposes set out in this privacy policy unless a longer
        retention period is required
      </p>
      <h2 className="hl-tl">How do we keep your information safe?</h2>
      <p className="hl-con">
        We aim to protect your personal information through a system of
        organizational and technical security measures. We have implemented
        appropriate technical and organizational security measures designed to
        protect the security of any personal information we process.
      </p>
      <h2 className="hl-tl">Grievance Redressal</h2>
      <p className="hl-con-ls1">
        If you have any complaints, concerns with regards to the use, storage,
        deletion, and disclosure of your personal information provided to Us,
        you may reach out to us at the email given below
      </p>
      <p className="hl-con-ls2">Email- contact@thepenindia.com</p>
      <p className="hl-con-ls3">
        Address - Oliver Rd, Kattukoil Garden, Mylapore
        <br />
        22. First Floor, Chennai, Tamil Nadu, 600004
      </p>
    </div>
  );
};

export default PrivacyPolicy;
