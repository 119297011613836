import { useState, useRef } from "react";
import "../styles/Navbar.scss";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
function Menubar(
  scrollToPage: any,
  aboutRef: any,
  serviceRef: any,
  joinusRef: any,
  contactRef: any,
  homeRef: any
) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = (): void => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="menubar">
      <div className="burger-icon" onClick={handleClick}>
        <MenuIcon />
      </div>
      <div className={`navbar ${isOpen ? "open" : ""}`}>
        <div className="navbar_options">
          <div
            className="close_icon_div d-flex align-items-end"
            onClick={() => setIsOpen(false)}
          >
            <CloseIcon className="close_icon" />
          </div>
          <div className="nav_options px-4">
            <div
              className="nav_link_mobile ps-2"
              onClick={() => {
                scrollToPage(homeRef);
                setIsOpen(false);
              }}
            >
              Home
            </div>
            <div
              className="nav_link_mobile ps-2"
              onClick={() => {
                scrollToPage(aboutRef);
                setIsOpen(false);
              }}
            >
              About Us
            </div>
            <div
              className="nav_link_mobile ps-2"
              onClick={() => {
                scrollToPage(serviceRef);
                setIsOpen(false);
              }}
            >
              Services
            </div>
            <div
              className="nav_link_mobile ps-2"
              onClick={() => {
                scrollToPage(joinusRef);
                setIsOpen(false);
              }}
            >
              Join Us
            </div>
            <div
              className="nav_link_mobile ps-2"
              onClick={() => {
                scrollToPage(contactRef);
                setIsOpen(false);
              }}
            >
              Contact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Menubar;
