import React, { useEffect, useState, useRef } from "react";
import "./styles/Main.scss";
import topCarouselContainer from "./componenets/Carousel";
import NavBg from "./assets/headerVector.png"
import Logo from "./assets/pen_logo.png";
import JoinUsContainer from "./componenets/JoinUs";
import CareerContainer from "./componenets/Career";
import { Services } from "./componenets/Services";
import { About } from "./componenets/About";
import { Contact } from "./componenets/Contact";
import { Footer } from "./componenets/Footer";
import Menubar from "./componenets/Navbar";
const Main: React.FC = () => {
  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<HTMLDivElement>(null);
  const joinusRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const scrollToPage = (pageRef: React.RefObject<HTMLDivElement>) => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <nav className="nav_option_div">
        <div className="pen_logo_component d-flex justify-content-start align-items-center ps-3">
          <img src={Logo} alt="Pen Logo" className="pen_logo" />
        </div>
        <ul className="nav_bar_main_list_component d-flex justify-content-end align-items-center">
          {/* <div className="nav-bar-bg"><img src={NavBg} /></div> */}
          <li className="navbar_list_comp">
            <button
              className="navbar_link_button"
              onClick={() => scrollToPage(aboutRef)}
            >
              ABOUT PEN
            </button>
          </li>
          <li className="navbar_list_comp">
            <button
              className="navbar_link_button"
              onClick={() => scrollToPage(serviceRef)}
            >
              SERVICES
            </button>
          </li>
          <li className="navbar_list_comp">
            <button
              className="navbar_link_button"
              onClick={() => scrollToPage(joinusRef)}
            >
              JOIN US
            </button>
          </li>
          <li className="navbar_list_comp">
            <button
              className="navbar_link_button"
              onClick={() => scrollToPage(contactRef)}
            >
              CONTACT
            </button>
          </li>
        </ul>
        <div className="menubar_div">
          {Menubar(
            scrollToPage,
            aboutRef,
            serviceRef,
            joinusRef,
            contactRef,
            homeRef
          )}
        </div>
      </nav>
      <div ref={homeRef} className="top_carousel_main_div">
        {topCarouselContainer()}
      </div>
      <div ref={aboutRef} className="aboutus_main_component_div">
        <About />
      </div>
      <div ref={serviceRef} className="services_main_component_div">
        <Services />
      </div>
      <div ref={joinusRef} className="join_us_main_component_div">
        {JoinUsContainer()}
      </div>
      <div className="career_main_component_div"> {CareerContainer()} </div>
      <div ref={contactRef} className="contactus_main_component_div">
        <Contact />
      </div>
      <div className="main_footer_div">
        {Footer(
          scrollToPage,
          aboutRef,
          serviceRef,
          joinusRef,
          contactRef,
          homeRef
        )}
      </div>
    </div>
  );
};
export default Main;
