import React, { useEffect, useState, useRef } from "react";
import "../styles/Career.scss";
import test_image from "../assets/Career.jpg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Input } from "@material-ui/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { motion } from "framer-motion";
interface FormData {
  position: string;
  email: string;
  cv: File | any;
  name: string;
}

function CareerContainer() {
  const OpeningList = [
    {
      id: 1,
      title: "Junior Content Writer",
      experience: "2+ years",
      jobDescription:
        "Will be writing content for websites and public distribution channels and have a passion for writing. Should be able to write in both English & Tamil",
    },
    {
      id: 2,
      title: "Visual Designer",
      experience: "3 to 6 years",
      jobDescription:
        "Senior Graphic Designer will strategically combine design elements to develop aesthetically appealing layouts, advertisements, reports, logos, packaging.",
    }
  ];
  const [fileName, setFileName] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({
    position: "",
    email: "",
    cv: null,
    name: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [selectedJob, setSelectedJob] = useState<any>(OpeningList[0]);
  const [isLoading, setIsLoading] = useState(false);

  const handleJobClick = (job: any) => {
    setSelectedJob(job);
  };
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCVUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
    }
    setFormData((prevState) => ({
      ...prevState,
      cv: file || null,
    }));
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("application_type", formData.position);
    formDataToSend.append("applicant_email", formData.email);
    formDataToSend.append("file", formData.cv);
    formDataToSend.append("applicant_name", formData.name);
    console.log(formData, "formdata");
    // const response = await fetch(
    //   "https://stage-emailtrigger.azurewebsites.net/api/trigger-queue?code=FffeiWXEBEU8dDq4GbkjkH1mqGLu7uzfs4s8RHNogYpFAzFuWBFzTg==",
    //   {
    //     method: "POST",
    //     body: formDataToSend,
    //   }
    // );
    // if (response.status === 200) {
    //   setAlertMessage("Thanks for submit your application");
    //   setAlertColor("green");
    // } else {
    //   setAlertMessage("Failed to submit your application");
    //   setAlertColor("red");
    // }
    try {
      await new Promise((resolve) => setTimeout(resolve, 10000));
      const response = await fetch(
        "https://stage-emailtrigger.azurewebsites.net/api/trigger-queue?code=FffeiWXEBEU8dDq4GbkjkH1mqGLu7uzfs4s8RHNogYpFAzFuWBFzTg==",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.status === 200) {
        setAlertMessage("Thanks for submitting your application");
        setAlertColor("green");
      } else {
        setAlertMessage("Failed to submit your application");
        setAlertColor("red");
      }
    } catch (error) {
      console.error(error);
      setAlertMessage("Failed to submit your application");
      setAlertColor("red");
    }

    setIsLoading(false); // Stop loading
  };
  const displaySelectedPost = () => {};
  return (
    <div className="career_joinus_container  d-flex justify-content-center align-items-center">
      <div className="career_main_left_div">
        <div className="career_title_div">Career</div>
        <div className="career_open_positions_div">
          <div className="positions_table">
            <div className="positions_tabel_title"> Current Openings</div>
            <div className="positions_table_data_div">
              {OpeningList.map((item: any) => {
                return (
                  <div
                    // className="positions_name_div"
                    onClick={() => handleJobClick(item)}
                    className={`positions_name_div ${
                      selectedJob && selectedJob.id === item.id
                        ? "selected"
                        : ""
                    }`}
                    key={item.id}
                  >
                    {item.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="career_main_right_div">
        <div className="career_main_image_div">
          {selectedJob && (
            <div className="job-details">
              <h1 className="job_title"> {selectedJob?.title}</h1>
              <div className="job_experience">
                <span style={{ fontWeight: "bolder" }}>Experience Level:</span>{" "}
                {selectedJob?.experience}
              </div>
              <div className="job_description">
                <span style={{ fontWeight: "bolder" }}>Description:</span>{" "}
                {selectedJob?.jobDescription}
              </div>
            </div>
          )}
        </div>
        <div className="career_decor_div"></div>
      </div>
      <div className="career_form_div">
        <div className="career_form_title">
          For Internships and Fellowships opportunities please share your resume
        </div>

        <div className="career_form_main_div">
          <form
            onSubmit={handleSubmit}
            method="POST"
            className="form_main_component"
          >
            <div className="inputbox_and_label_div">
              <div className="label_div">Name</div>
              <div className="input_div">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="main_input_div"
                />
              </div>
            </div>
            <div className="inputbox_and_label_div">
              <div className="label_div">Email ID</div>
              <div className="input_div">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="main_input_div"
                />
              </div>
            </div>
            <div className="inputbox_and_label_div">
              <div className="label_div">Apply For Positions</div>
              <div className="input_div">
                <Select
                  name="position"
                  onChange={handleInputChange}
                  value={formData.position}
                  className="main_input_div position"
                >
                  {OpeningList.map((item: any) => {
                    return <MenuItem value={item.title}>{item.title}</MenuItem>;
                  })}
                  {/* <MenuItem value="">Select a position</MenuItem>
                  <MenuItem value="developer">Developer</MenuItem>
                  <MenuItem value="designer">Designer</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem> */}
                </Select>
              </div>
            </div>
            <div className="inputbox_and_label_div">
              <div className="label_div">Upload Your CV</div>
              <div className="input_div">
                <label htmlFor="cv-upload" className="upload-icon-label">
                  <FileUploadIcon className="upload-icon ps-2" />
                  {fileName && <div className="file-name">{fileName}</div>}
                </label>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  id="cv-upload"
                  onChange={handleCVUpload}
                  className="main_input_div_file_upload"
                />
              </div>
            </div>
            <div className="career_inputbox_submit_button">
              <button
                type="submit"
                className={`submit_button ${isLoading ? "loading" : ""}`}
                disabled={isLoading}
              >
                <span>Submit</span>
                {isLoading ? <div className="loading-layer"></div> : ""}
              </button>
            </div>
          </form>
          <div className={`alertmessage-career ${alertColor}`}>
            {alertMessage && (
              <div className="alert_message-career">{alertMessage}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CareerContainer;
