import "../styles/Services.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import { motion } from "framer-motion";

export const Services = () => {
  const ServicesData = [
    {
      id: 1,
      title: "Public Opinion Research",
      image: "https://picsum.photos/800/600/?random",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="187.91"
          height="189.836"
          viewBox="0 0 187.91 189.836"
        >
          <g
            id="Group_15065"
            data-name="Group 15065"
            transform="translate(-10079.761 -1733)"
          >
            <g id="text" transform="translate(10081.261 1737.601)">
              <g
                id="Group_15061"
                data-name="Group 15061"
                transform="translate(20.471 1.3)"
              >
                <path
                  id="Path_5470"
                  data-name="Path 5470"
                  d="M82.083,6.61V37.587a5.288,5.288,0,0,1-5.31,5.31H62.612a2.58,2.58,0,0,0-2.655,1.77l-4.72,10.621a2.876,2.876,0,0,1-5.31,0L44.911,44.667a2.835,2.835,0,0,0-2.655-1.77H27.21a5.288,5.288,0,0,1-5.31-5.31V6.61A5.288,5.288,0,0,1,27.21,1.3H76.773a5.485,5.485,0,0,1,5.31,5.31Z"
                  transform="translate(20.287 -1.3)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
                <path
                  id="Path_5471"
                  data-name="Path 5471"
                  d="M81.072,21.045V40.221a3.274,3.274,0,0,1-3.245,3.245H69.861a2.58,2.58,0,0,0-2.655,1.77l-1.475,3.54a2.876,2.876,0,0,1-5.31,0l-1.77-3.54A2.835,2.835,0,0,0,56,43.466h-8.85A3.274,3.274,0,0,1,43.9,40.221V21.045A3.274,3.274,0,0,1,47.145,17.8H77.827A3.274,3.274,0,0,1,81.072,21.045Z"
                  transform="translate(63.191 30.878)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
                <path
                  id="Path_5472"
                  data-name="Path 5472"
                  d="M44.477,21.045V40.221a3.274,3.274,0,0,1-3.245,3.245H33.266a2.58,2.58,0,0,0-2.655,1.77l-1.475,3.54a2.876,2.876,0,0,1-5.31,0l-1.77-3.54a2.835,2.835,0,0,0-2.655-1.77H10.845A3.274,3.274,0,0,1,7.6,40.221V21.045A3.274,3.274,0,0,1,10.845,17.8H41.232A3.274,3.274,0,0,1,44.477,21.045Z"
                  transform="translate(-7.6 30.878)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
              </g>
              <g
                id="Group_15063"
                data-name="Group 15063"
                transform="translate(1 81.831)"
              >
                <path
                  id="Path_5478"
                  data-name="Path 5478"
                  d="M49.678,46.8a22.329,22.329,0,0,0-4.425-.3H28.141A27.07,27.07,0,0,0,1,73.641V94.588H47.907"
                  transform="translate(-1 6.316)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
                <path
                  id="Path_5479"
                  data-name="Path 5479"
                  d="M43.872,51.412c0,10.326-8.26,19.471-18.586,19.471S6.7,61.442,6.7,51.412s8.26-17.406,18.586-17.406C35.612,33.711,43.872,41.086,43.872,51.412Z"
                  transform="translate(10.116 -18.066)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
                <g
                  id="Group_15062"
                  data-name="Group 15062"
                  transform="translate(72.869)"
                >
                  <path
                    id="Path_5482"
                    data-name="Path 5482"
                    d="M48.27,94.488H95.178V73.541A27.07,27.07,0,0,0,68.036,46.4H50.925a22.329,22.329,0,0,0-4.425.3"
                    transform="translate(14.863 6.121)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="5"
                  />
                  <path
                    id="Path_5483"
                    data-name="Path 5483"
                    d="M81.772,51.406c0,10.326-8.26,19.471-18.586,19.471S44.6,61.436,44.6,51.406,52.86,34,63.186,34,81.772,41.08,81.772,51.406Z"
                    transform="translate(11.158 -18.061)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="5"
                  />
                  <path
                    id="Path_5484"
                    data-name="Path 5484"
                    d="M62.872,46.012c0,10.326-8.26,19.471-18.586,19.471S25.7,56.042,25.7,46.012s8.26-17.406,18.586-17.406C54.612,28.311,62.872,35.686,62.872,46.012Z"
                    transform="translate(-25.7 -28.597)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="5"
                  />
                </g>
                <path
                  id="Path_5487"
                  data-name="Path 5487"
                  d="M91.294,105.018V68.141A27.07,27.07,0,0,0,64.152,41H47.041A27.07,27.07,0,0,0,19.9,68.141v36.877Z"
                  transform="translate(35.858 -4.41)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke-width="5"
                />
              </g>
            </g>
            <text
              id="A"
              transform="translate(10174 1774)"
              stroke="#000"
              stroke-width="1"
              font-size="38"
              font-family="Poppins-Regular, Poppins"
            >
              <tspan x="-12.806" y="0">
                A
              </tspan>
            </text>
            <text
              id="B"
              transform="translate(10120.5 1808.5)"
              stroke="#000"
              stroke-width="1"
              font-size="23"
              font-family="Poppins-Regular, Poppins"
            >
              <tspan x="-7.049" y="0">
                B
              </tspan>
            </text>
            <text
              id="C"
              transform="translate(10227.5 1808.5)"
              stroke="#000"
              stroke-width="1"
              font-size="23"
              font-family="Poppins-Regular, Poppins"
            >
              <tspan x="-8.878" y="0">
                C
              </tspan>
            </text>
          </g>
        </svg>
      ),
      description:
        "Customized research design and methodology, data collection through surveys, polls, and focus groups, thorough data analysis and interpretation of findings, strategic consultation for informed decision-making.",
    },
    {
      id: 2,
      title: "Agenda Building",
      image: "https://picsum.photos/800/600/?random",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="181.435"
          height="181.435"
          viewBox="0 0 181.435 181.435"
        >
          <defs>
            <clipPath id="clip-path">
              <path
                id="path1403"
                d="M0-682.665H181.434v181.434H0Z"
                transform="translate(0 682.665)"
              />
            </clipPath>
          </defs>
          <g id="g1389" transform="translate(0 682.665)">
            <g id="g1391" transform="translate(78.274 -638.167)">
              <path
                id="path1393"
                d="M-24.17-61.7l-8.749-22.335"
                transform="translate(32.919 84.038)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="6"
              />
            </g>
            <g id="g1395" transform="translate(94.412 -638.168)">
              <path
                id="path1397"
                d="M-24.17,0l-8.749,22.336"
                transform="translate(32.919)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="6"
              />
            </g>
            <g id="g1399" transform="translate(0 -682.665)">
              <g id="g1401" clip-path="url(#clip-path)">
                <g id="g1407" transform="translate(95.985 41.251)">
                  <path
                    id="path1409"
                    d="M0,0,8.681,2.8a11.836,11.836,0,0,1,6.194,4.663"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1411" transform="translate(66.138 41.251)">
                  <path
                    id="path1413"
                    d="M0-70.674,2.634-84.421a11.838,11.838,0,0,1,8-9.04l8.681-2.8"
                    transform="translate(0 96.257)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1415" transform="translate(86.025 36.731)">
                  <path
                    id="path1417"
                    d="M-25.925,0V5.809A4.692,4.692,0,0,1-30.617,10.5a4.692,4.692,0,0,1-4.692-4.692V0"
                    transform="translate(35.309)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1419" transform="translate(78.274 6.598)">
                  <path
                    id="path1421"
                    d="M-34.376,0A12.443,12.443,0,0,0-46.82,12.443V17.85A12.641,12.641,0,0,0-34.376,30.133,12.641,12.641,0,0,0-21.933,17.85V12.443A12.443,12.443,0,0,0-34.376,0Z"
                    transform="translate(46.82)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1423" transform="translate(66.478 110.252)">
                  <path
                    id="path1425"
                    d="M0,0V14.761"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1427" transform="translate(66.478 81.663)">
                  <path
                    id="path1429"
                    d="M0,0V16.046"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1431" transform="translate(114.956 81.663)">
                  <path
                    id="path1433"
                    d="M0,0V43.35"
                    transform="translate(0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1435" transform="translate(56.818 67.77)">
                  <path
                    id="path1437"
                    d="M-159.854,0h-54.308a3.556,3.556,0,0,0-3.556,3.556V9.274a3.556,3.556,0,0,0,3.556,3.556h35.227"
                    transform="translate(217.718)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1439" transform="translate(108.144 76.163)">
                  <path
                    id="path1441"
                    d="M0-12.259H12.916a3.556,3.556,0,0,0,3.556-3.556V-16.7"
                    transform="translate(0 16.696)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1443" transform="translate(97.031 121.601)">
                  <path
                    id="path1445"
                    d="M0,0,3.911,1.26A12.439,12.439,0,0,1,9.843,5.366"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1447" transform="translate(74.56 121.601)">
                  <path
                    id="path1449"
                    d="M0-14.824A12.442,12.442,0,0,1,5.932-18.93l3.911-1.26"
                    transform="translate(0 20.191)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1451" transform="translate(79.783 96.838)">
                  <path
                    id="path1453"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1455" transform="translate(141.061 121.601)">
                  <path
                    id="path1457"
                    d="M0,0,3.911,1.26A12.439,12.439,0,0,1,9.843,5.366"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1459" transform="translate(118.59 121.601)">
                  <path
                    id="path1461"
                    d="M0-14.824A12.441,12.441,0,0,1,5.931-18.93l3.911-1.26"
                    transform="translate(0 20.191)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1463" transform="translate(123.814 96.838)">
                  <path
                    id="path1465"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1467" transform="translate(53.001 121.601)">
                  <path
                    id="path1469"
                    d="M0,0,3.911,1.26A12.442,12.442,0,0,1,9.843,5.366"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1471" transform="translate(30.443 121.601)">
                  <path
                    id="path1473"
                    d="M0-15.129a12.442,12.442,0,0,1,6.019-4.216l3.912-1.26"
                    transform="translate(0 20.605)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1475" transform="translate(35.754 96.838)">
                  <path
                    id="path1477"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1479" transform="translate(31.119 150.761)">
                  <path
                    id="path1481"
                    d="M0,0,5.81,1.872a10.4,10.4,0,0,1,7.026,7.943l2.733,14.261"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1483" transform="translate(2.658 150.761)">
                  <path
                    id="path1485"
                    d="M0-66.512,2.732-80.773a10.4,10.4,0,0,1,7.026-7.943l5.81-1.872"
                    transform="translate(0 90.588)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1487" transform="translate(13.739 125.954)">
                  <path
                    id="path1489"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1491" transform="translate(75.148 150.761)">
                  <path
                    id="path1493"
                    d="M0,0,5.81,1.872a10.4,10.4,0,0,1,7.026,7.943l2.732,14.261"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1495" transform="translate(46.687 150.761)">
                  <path
                    id="path1497"
                    d="M0-66.512,2.732-80.773a10.4,10.4,0,0,1,7.026-7.943l5.809-1.872"
                    transform="translate(0 90.588)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1499" transform="translate(57.768 125.954)">
                  <path
                    id="path1501"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1503" transform="translate(119.178 150.761)">
                  <path
                    id="path1505"
                    d="M0,0,5.81,1.872a10.4,10.4,0,0,1,7.026,7.943l2.732,14.261"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1507" transform="translate(90.716 150.761)">
                  <path
                    id="path1509"
                    d="M0-66.512,2.732-80.773a10.4,10.4,0,0,1,7.027-7.943l5.809-1.872"
                    transform="translate(0 90.588)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1511" transform="translate(101.798 125.954)">
                  <path
                    id="path1513"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1515" transform="translate(163.208 150.761)">
                  <path
                    id="path1517"
                    d="M0,0,5.81,1.872a10.4,10.4,0,0,1,7.026,7.943l2.733,14.261"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1519" transform="translate(134.746 150.761)">
                  <path
                    id="path1521"
                    d="M0-66.512,2.732-80.773a10.4,10.4,0,0,1,7.026-7.943l5.81-1.872"
                    transform="translate(0 90.588)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1523" transform="translate(145.827 125.954)">
                  <path
                    id="path1525"
                    d="M-30.205,0A10.933,10.933,0,0,0-41.139,10.934v4.75A11.107,11.107,0,0,0-30.205,26.477,11.107,11.107,0,0,0-19.271,15.684v-4.75A10.933,10.933,0,0,0-30.205,0Z"
                    transform="translate(41.139)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1527" transform="translate(110.959 29.971)">
                  <path
                    id="path1529"
                    d="M-100.444-9.863a23.611,23.611,0,0,0-11.543-3.551,23.756,23.756,0,0,0-12.021,2.7h-8.3a1.772,1.772,0,0,0-1.772,1.772v8.3a23.765,23.765,0,0,0-2.7,12.021,23.929,23.929,0,0,0,23.07,22.871,23.85,23.85,0,0,0,24.6-24.6A23.577,23.577,0,0,0-91.875-.7"
                    transform="translate(136.802 13.434)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1531" transform="translate(124.321 45.506)">
                  <path
                    id="path1533"
                    d="M0,0H22.509"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1535" transform="translate(135.576 42.899)">
                  <path
                    id="path1537"
                    d="M0,0V21.134"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1539" transform="translate(145.717 46.569)">
                  <path
                    id="path1541"
                    d="M0,0V9.465"
                    transform="translate(0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1543" transform="translate(125.435 46.569)">
                  <path
                    id="path1545"
                    d="M0-26.148v-9.465"
                    transform="translate(0 35.613)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1547" transform="translate(143.032 56.978)">
                  <path
                    id="path1549"
                    d="M0,0H5.369"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1551" transform="translate(122.751 56.978)">
                  <path
                    id="path1553"
                    d="M0,0H5.369"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
                <g id="g1555" transform="translate(129.968 64.742)">
                  <path
                    id="path1557"
                    d="M0,0H11.215"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="10"
                    stroke-width="6"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
      description:
        "Analysis of public opinion research data and collaboration with clients to ideate and create a compelling manifesto. Approach involves integrating research insights to ensure a collaborative and impactful agenda-building process.",
    },
    {
      id: 3,
      title: "Policy Analysis",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="171.174"
          height="181.434"
          viewBox="0 0 171.174 181.434"
        >
          <g
            id="Group_15064"
            data-name="Group 15064"
            transform="translate(-9626 -1738.901)"
          >
            <g id="report" transform="translate(9611.521 1738.901)">
              <g
                id="Group_15059"
                data-name="Group 15059"
                transform="translate(14.478)"
              >
                <path
                  id="Path_5458"
                  data-name="Path 5458"
                  d="M182.994,94.494a2.658,2.658,0,0,0,2.658-2.658V4.43A4.434,4.434,0,0,0,181.222,0H55.123a4.434,4.434,0,0,0-4.43,4.43V84.744H41.121a6.2,6.2,0,0,0-5.371,9.3l5.289,9.161a6.206,6.206,0,0,0-5.362,6.138v5.143a6.158,6.158,0,0,0,.988,3.346,42.971,42.971,0,0,0-11.3,7.953,35.268,35.268,0,0,0-10.891,25.128c0,11.2,5.62,21.764,15.417,28.989a7.967,7.967,0,0,0,4.7,1.53H181.223a4.434,4.434,0,0,0,4.43-4.43V104.239a2.658,2.658,0,0,0-5.315,0v71.88H78.954A35.83,35.83,0,0,0,89.4,156.736a2.658,2.658,0,0,0-5.235-.923c-1.407,7.98-6.194,15.164-13.511,20.306H34.594a2.614,2.614,0,0,1-1.545-.493c-8.425-6.212-13.257-15.219-13.257-24.711a30,30,0,0,1,9.31-21.344,38.654,38.654,0,0,1,14.513-8.881H60.806c11.669,4.048,20.5,12.9,23.047,23.788a2.658,2.658,0,0,0,5.175-1.213c-2.573-10.978-10.532-20.156-21.3-25.434a6.159,6.159,0,0,0,.987-3.343v-5.143a6.206,6.206,0,0,0-5.362-6.138l5.289-9.161a6.2,6.2,0,0,0-5.371-9.3H56.009V5.315H180.337V91.837a2.657,2.657,0,0,0,2.657,2.658ZM63.4,109.345v5.143a.887.887,0,0,1-.886.886H41.879a.887.887,0,0,1-.886-.886v-5.143a.887.887,0,0,1,.886-.886H62.514A.887.887,0,0,1,63.4,109.345ZM63.271,90.06a.886.886,0,0,1,.767,1.329l-6.787,11.755H47.14L40.353,91.389a.886.886,0,0,1,.767-1.329Z"
                  transform="translate(-14.478)"
                />
                <path
                  id="Path_5460"
                  data-name="Path 5460"
                  d="M262.56,125.42v-25.8a3.725,3.725,0,0,0-3.721-3.721h-6.733a3.725,3.725,0,0,0-3.721,3.721v24.2H243.72v-34.9A3.725,3.725,0,0,0,240,85.2h-6.733a3.725,3.725,0,0,0-3.721,3.721v34.9h-4.666V79.652a3.725,3.725,0,0,0-3.721-3.721h-6.733a3.725,3.725,0,0,0-3.721,3.721v44.174h-4.666v-14.56a2.658,2.658,0,0,0-5.315,0v14.56h-3.544V88.971h3.544v7.892a2.658,2.658,0,1,0,5.315,0V87.377a3.725,3.725,0,0,0-3.721-3.721h-6.733a3.725,3.725,0,0,0-3.721,3.721v36.449H187.2V96.132a3.725,3.725,0,0,0-3.721-3.721h-6.733a3.725,3.725,0,0,0-3.721,3.721v27.694h-4.666V105.079a3.725,3.725,0,0,0-3.721-3.721H157.9a3.725,3.725,0,0,0-3.721,3.721V125.42a3.725,3.725,0,0,0,3.721,3.721H258.84a3.724,3.724,0,0,0,3.72-3.72Zm-99.518-1.595H159.5V106.674h3.544Zm18.84,0h-3.544v-26.1h3.544Zm37.681,0H216.02V81.246h3.544Zm18.84,0H234.86V90.516H238.4Zm18.84,0H253.7V101.219h3.544Z"
                  transform="translate(-104.677 -49.024)"
                />
                <path
                  id="Path_5461"
                  data-name="Path 5461"
                  d="M360.994,280.079h-1.306a21.213,21.213,0,0,0-34.084-14.1l-.931-.929a2.658,2.658,0,0,0-1.878-.777h0a2.658,2.658,0,0,0-1.879.78,25.028,25.028,0,1,0,42.736,17.686,2.657,2.657,0,0,0-2.657-2.657Zm-22.37-13.263a15.944,15.944,0,0,1,15.695,13.263h-14.6L329.4,269.771A15.828,15.828,0,0,1,338.624,266.815Zm0,35.633a19.708,19.708,0,0,1-15.7-31.632l13.818,13.8a2.658,2.658,0,0,0,1.878.777h19.534A19.742,19.742,0,0,1,338.624,302.449Z"
                  transform="translate(-207.599 -168.834)"
                />
                <path
                  id="Path_5462"
                  data-name="Path 5462"
                  d="M205.81,276.1h29.761a2.658,2.658,0,1,0,0-5.315H205.81a2.658,2.658,0,1,0,0,5.315Z"
                  transform="translate(-136.293 -174.829)"
                />
                <path
                  id="Path_5463"
                  data-name="Path 5463"
                  d="M205.81,304.862h29.761a2.658,2.658,0,1,0,0-5.315H205.81a2.658,2.658,0,1,0,0,5.315Z"
                  transform="translate(-136.293 -193.398)"
                />
                <path
                  id="Path_5464"
                  data-name="Path 5464"
                  d="M217.315,333.624H243a2.658,2.658,0,1,0,0-5.315H217.315a2.658,2.658,0,1,0,0,5.315Z"
                  transform="translate(-143.721 -211.968)"
                />
                <path
                  id="Path_5465"
                  data-name="Path 5465"
                  d="M240.325,362.385h17.53a2.658,2.658,0,0,0,0-5.315h-17.53a2.658,2.658,0,0,0,0,5.315Z"
                  transform="translate(-158.577 -230.537)"
                />
                <path
                  id="Path_5466"
                  data-name="Path 5466"
                  d="M187.1,42.129a2.658,2.658,0,1,0,0-5.315H157.256a2.658,2.658,0,0,0,0,5.315Z"
                  transform="translate(-104.945 -23.768)"
                />
                <path
                  id="Path_5467"
                  data-name="Path 5467"
                  d="M401.006,42.129H417.4a2.658,2.658,0,0,0,0-5.315H401.006a2.658,2.658,0,1,0,0,5.315Z"
                  transform="translate(-262.318 -23.768)"
                />
                <path
                  id="Path_5468"
                  data-name="Path 5468"
                  d="M401.006,68.59H417.4a2.658,2.658,0,0,0,0-5.315H401.006a2.658,2.658,0,1,0,0,5.315Z"
                  transform="translate(-262.318 -40.853)"
                />
                <path
                  id="Path_5469"
                  data-name="Path 5469"
                  d="M253.958,434.036h57.681a8.209,8.209,0,0,0,0-16.418H253.958a8.209,8.209,0,0,0,0,16.418Zm43.98-11.1h13.7a2.893,2.893,0,1,1,0,5.787h-13.7Zm-43.98,0h38.664v5.787H253.958a2.893,2.893,0,1,1,0-5.787Z"
                  transform="translate(-163.795 -269.629)"
                />
              </g>
            </g>
            <text
              id="_"
              data-name="₹"
              transform="translate(9664 1902)"
              stroke="#000"
              stroke-width="1"
              font-size="38"
              font-family="Poppins-Regular, Poppins"
            >
              <tspan x="-12.065" y="0">
                ₹
              </tspan>
            </text>
          </g>
        </svg>
      ),
      image: "https://picsum.photos/800/600/?random",
      description:
        "Thorough analysis of existing policies and their effectiveness, conducting gap analysis to identify areas for improvement, providing custom policy recommendations for clients, offering research- based analysis and consultation for policy development, and integrating research insights into policy recommendations.",
    },
    {
      id: 4,
      title: "Social Movement Building",
      image: "https://picsum.photos/800/600/?random",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="152.171"
          height="181.434"
          viewBox="0 0 152.171 181.434"
        >
          <g id="Layer_20" data-name="Layer 20" transform="translate(-6 -1)">
            <path
              id="Path_5432"
              data-name="Path 5432"
              d="M128.05,31.121,98.786,1.857A2.926,2.926,0,0,0,96.717,1H8.926A2.926,2.926,0,0,0,6,3.926V161.95a2.926,2.926,0,0,0,2.926,2.926h76.3l2.757,15.156a2.926,2.926,0,0,0,2.877,2.4h17.558a2.926,2.926,0,0,0,2.877-3.45l-2.563-14.108h17.245a2.926,2.926,0,0,0,2.926-2.926v-6.257a134.174,134.174,0,0,1,12.759,4.5,8.75,8.75,0,0,0,16.5-4.1V103.423a8.75,8.75,0,0,0-16.5-4.1,134.167,134.167,0,0,1-12.759,4.5V33.19a2.926,2.926,0,0,0-.857-2.069ZM99.644,10.991l19.273,19.273H99.644ZM11.853,159.024V6.853H93.791V33.19a2.926,2.926,0,0,0,2.926,2.926h26.337v69.305a132.445,132.445,0,0,1-31.748,3.854H85.012a11.705,11.705,0,0,0-11.705,11.705v17.558a11.705,11.705,0,0,0,9.212,11.413l1.648,9.072Zm73.159-14.632a5.853,5.853,0,0,1-5.853-5.853V120.981a5.853,5.853,0,0,1,5.853-5.853h6.295c3.778,0,7.53-.19,11.264-.492v30.247c-3.734-.293-7.486-.492-11.264-.492Zm23.411-30.355a137.887,137.887,0,0,0,32.19-8v47.454a137.885,137.885,0,0,0-32.19-8ZM93.308,176.582l-4.785-26.337h2.789c2.976,0,5.935.135,8.882.331l4.717,26.007Zm29.746-17.558H107.671l-1.44-7.9a131.823,131.823,0,0,1,16.824,2.976Zm23.411-55.6a2.926,2.926,0,0,1,5.853,0V156.1a2.926,2.926,0,0,1-5.853,0Z"
              transform="translate(0)"
            />
            <path
              id="Path_5433"
              data-name="Path 5433"
              d="M33.411,59.822A23.411,23.411,0,1,0,10,36.411,23.411,23.411,0,0,0,33.411,59.822ZM30.485,33.485a2.926,2.926,0,1,1,2.926,2.926A2.926,2.926,0,0,1,30.485,33.485Zm-2.926,19.46V45.19a2.926,2.926,0,0,1,2.926-2.926h5.853a2.926,2.926,0,0,1,2.926,2.926v7.755A17.233,17.233,0,0,1,27.558,52.945Zm5.853-34.092a17.535,17.535,0,0,1,11.705,30.61V45.19a8.762,8.762,0,0,0-4.1-7.4,8.682,8.682,0,0,0,1.171-4.3,8.779,8.779,0,1,0-17.558,0,8.683,8.683,0,0,0,1.171,4.3,8.762,8.762,0,0,0-4.1,7.4v4.272a17.535,17.535,0,0,1,11.705-30.61Z"
              transform="translate(7.705 23.116)"
            />
            <path
              id="Path_5434"
              data-name="Path 5434"
              d="M34,15H63.264v5.853H34Z"
              transform="translate(53.938 26.969)"
            />
            <path
              id="Path_5435"
              data-name="Path 5435"
              d="M28,19H74.822v5.853H28Z"
              transform="translate(42.38 34.675)"
            />
            <path
              id="Path_5436"
              data-name="Path 5436"
              d="M28,23H74.822v5.853H28Z"
              transform="translate(42.38 42.38)"
            />
            <path
              id="Path_5437"
              data-name="Path 5437"
              d="M10,31H68.527v5.853H10Z"
              transform="translate(7.705 57.791)"
            />
            <path
              id="Path_5438"
              data-name="Path 5438"
              d="M10,35H45.116v5.853H10Z"
              transform="translate(7.705 65.496)"
            />
            <path
              id="Path_5439"
              data-name="Path 5439"
              d="M10,39H59.748v5.853H10Z"
              transform="translate(7.705 73.202)"
            />
            <path
              id="Path_5440"
              data-name="Path 5440"
              d="M10,43H39.264v5.853H10Z"
              transform="translate(7.705 80.907)"
            />
            <path
              id="Path_5441"
              data-name="Path 5441"
              d="M30,27H70.969v5.853H30Z"
              transform="translate(46.233 50.085)"
            />
            <path
              id="Path_5442"
              data-name="Path 5442"
              d="M12.926,22.558h64.38a2.926,2.926,0,0,0,2.926-2.926V7.926A2.926,2.926,0,0,0,77.306,5H12.926A2.926,2.926,0,0,0,10,7.926V19.632A2.926,2.926,0,0,0,12.926,22.558Zm2.926-11.705H74.38v5.853H15.853Z"
              transform="translate(7.705 7.705)"
            />
            <path
              id="Path_5443"
              data-name="Path 5443"
              d="M10,47H59.748v5.853H10Z"
              transform="translate(7.705 88.613)"
            />
            <path
              id="Path_5444"
              data-name="Path 5444"
              d="M10,51H59.748v5.853H10Z"
              transform="translate(7.705 96.318)"
            />
          </g>
        </svg>
      ),
      description:
        "Creation of effective digital marketing strategies, social media management, developing engaging content and messaging, executing PR and media outreach campaigns, and fostering social movements through strategic community partnerships and collaborations.",
    },
    {
      id: 5,
      title: "Electoral Analysis",
      image: "https://picsum.photos/800/600/?random",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="225.432"
          height="225.432"
          viewBox="0 0 225.432 225.432"
        >
          <defs>
            <clipPath id="clip-path">
              <path
                id="path732"
                d="M0-682.665H225.432v225.432H0Z"
                transform="translate(0 682.665)"
              />
            </clipPath>
          </defs>
          <g id="analytics" transform="translate(0 -0.002)">
            <g id="g726" transform="translate(0 0.002)">
              <g id="g728">
                <g id="g730" clip-path="url(#clip-path)">
                  <g id="g736" transform="translate(31.673 43.611)">
                    <path
                      id="path738"
                      d="M-330.234,0H-490.3a2.753,2.753,0,0,0-2.753,2.753V115.507a2.753,2.753,0,0,0,2.753,2.753h160.064a2.753,2.753,0,0,0,2.753-2.753V2.753A2.753,2.753,0,0,0-330.234,0Z"
                      transform="translate(493.051)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g740" transform="translate(31.673 43.611)">
                    <path
                      id="path742"
                      d="M-330.234,0H-490.3a2.753,2.753,0,0,0-2.753,2.753v14.3h165.57V2.753A2.753,2.753,0,0,0-330.234,0Z"
                      transform="translate(493.051)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g744" transform="translate(31.673 43.611)">
                    <path
                      id="path746"
                      d="M-42.211,0H-60.269a2.753,2.753,0,0,0-2.753,2.753v14.3h20.811Z"
                      transform="translate(63.023)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g748" transform="translate(82.541 177.928)">
                    <path
                      id="path750"
                      d="M0,0V4.6A5.3,5.3,0,0,0,5.284,9.888H58.549A5.3,5.3,0,0,0,63.832,4.6V0Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g752" transform="translate(55.537 75.947)">
                    <path
                      id="path754"
                      d="M-73.3-119.869a22.962,22.962,0,0,1-22.961-22.961A22.962,22.962,0,0,1-73.3-165.793a22.962,22.962,0,0,1,22.961,22.962A22.962,22.962,0,0,1-73.3-119.869Zm0-59.1a36.138,36.138,0,0,0-36.138,36.138A36.138,36.138,0,0,0-73.3-106.693a36.137,36.137,0,0,0,36.138-36.137A36.138,36.138,0,0,0-73.3-178.969Z"
                      transform="translate(109.434 178.969)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g756" transform="translate(91.675 75.947)">
                    <path
                      id="path758"
                      d="M0,0V13.176A22.961,22.961,0,0,1,22.961,36.138H36.138A36.138,36.138,0,0,0,0,0Z"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g760" transform="translate(106.042 112.085)">
                    <path
                      id="path762"
                      d="M-17.432,0a22.913,22.913,0,0,1-8.595,17.906l8.248,10.28A36.068,36.068,0,0,0-4.256,0Z"
                      transform="translate(26.027)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g764" transform="translate(68.713 89.124)">
                    <path
                      id="path766"
                      d="M-93.144-46.572A22.962,22.962,0,0,1-116.106-23.61a22.962,22.962,0,0,1-22.962-22.961,22.962,22.962,0,0,1,22.962-22.961A22.962,22.962,0,0,1-93.144-46.572Z"
                      transform="translate(139.068 69.533)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g768" transform="translate(84.064 106.907)">
                    <path
                      id="path770"
                      d="M0-11.135l6.31,6.31,11.8-11.8"
                      transform="translate(0 16.625)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g772" transform="translate(146.374 79.718)">
                    <path
                      id="path774"
                      d="M0,0H7.269"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g776" transform="translate(146.374 105.59)">
                    <path
                      id="path778"
                      d="M0,0H7.269"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g780" transform="translate(146.374 131.461)">
                    <path
                      id="path782"
                      d="M0,0H7.269"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g784" transform="translate(146.374 90.934)">
                    <path
                      id="path786"
                      d="M0,0H17.614"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g788" transform="translate(146.374 116.806)">
                    <path
                      id="path790"
                      d="M0,0H17.614"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g792" transform="translate(146.374 142.677)">
                    <path
                      id="path794"
                      d="M0,0H17.614"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g796" transform="translate(3.309 177.928)">
                    <path
                      id="path798"
                      d="M-237.533-40.112h89.812A11.924,11.924,0,0,0-135.831-52v-5.325a2.562,2.562,0,0,0-2.562-2.562h-213.69a2.562,2.562,0,0,0-2.562,2.562V-52a11.924,11.924,0,0,0,11.889,11.889h89.812"
                      transform="translate(354.644 59.888)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                  <g id="g800" transform="translate(15.691 27.727)">
                    <path
                      id="path802"
                      d="M-400.671-134.451v-48.742a17.6,17.6,0,0,0-17.547-17.547h-162.45a17.6,17.6,0,0,0-17.547,17.547V-68.086a17.6,17.6,0,0,0,17.547,17.547h162.45a17.6,17.6,0,0,0,17.547-17.547V-119.04"
                      transform="translate(598.216 200.74)"
                      fill="none"
                      stroke="#000"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="7"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      ),
      description:
        "Comparative analysis of election data from multiple sources, data visualization to identify patterns and trends, and custom insights and recommendations based on the comparison data.",
    },
    {
      id: 6,
      title: "Real-Time Poll Management Applications ",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="193.983"
          height="184.434"
          viewBox="0 0 193.983 184.434"
        >
          <g id="polling" transform="translate(1.5 1.168)">
            <path
              id="Path_5454"
              data-name="Path 5454"
              d="M154.107,185.627h47.746a4.77,4.77,0,0,0,4.773-4.773,23.843,23.843,0,0,0-13.8-21.564,18.908,18.908,0,0,0,4.247-11.858,19.1,19.1,0,1,0-38.2,0,18.9,18.9,0,0,0,4.247,11.858,23.848,23.848,0,0,0-13.8,21.564,4.773,4.773,0,0,0,4.775,4.773Zm23.872-47.744a9.549,9.549,0,1,1-9.549,9.549A9.549,9.549,0,0,1,177.979,137.882Zm-4.773,28.647h9.549a14.351,14.351,0,0,1,13.507,9.549H159.7A14.355,14.355,0,0,1,173.206,166.53Zm0,0"
              transform="translate(-82.489 -70.705)"
              stroke="#f2f2f2"
              stroke-width="3"
            />
            <path
              id="Path_5455"
              data-name="Path 5455"
              d="M15.441,57.627H63.188a4.773,4.773,0,0,0,4.775-4.773,23.848,23.848,0,0,0-13.8-21.564,18.9,18.9,0,0,0,4.247-11.858,19.1,19.1,0,1,0-38.2,0,18.908,18.908,0,0,0,4.247,11.858,23.844,23.844,0,0,0-13.8,21.564,4.77,4.77,0,0,0,4.773,4.773ZM39.315,9.882a9.549,9.549,0,1,1-9.55,9.549A9.549,9.549,0,0,1,39.315,9.882ZM34.54,38.53h9.549A14.355,14.355,0,0,1,57.6,48.078H21.033A14.351,14.351,0,0,1,34.54,38.53Zm0,0"
              transform="translate(-5.893)"
              stroke="#f2f2f2"
              stroke-width="3"
            />
            <path
              id="Path_5456"
              data-name="Path 5456"
              d="M292.775,121.627H340.52a4.77,4.77,0,0,0,4.775-4.773,23.844,23.844,0,0,0-13.8-21.564,18.908,18.908,0,0,0,4.247-11.858,19.1,19.1,0,0,0-38.2,0A18.908,18.908,0,0,0,301.8,95.289,23.844,23.844,0,0,0,288,116.853a4.77,4.77,0,0,0,4.775,4.773Zm23.872-47.744a9.549,9.549,0,1,1-9.549,9.549A9.549,9.549,0,0,1,316.647,73.882Zm-4.775,28.647h9.55a14.354,14.354,0,0,1,13.507,9.549H298.365A14.354,14.354,0,0,1,311.872,102.53Zm0,0"
              transform="translate(-159.087 -35.353)"
              stroke="#f2f2f2"
              stroke-width="3"
            />
            <path
              id="Path_5457"
              data-name="Path 5457"
              d="M186.208,254.707h-4.775V183.089a4.77,4.77,0,0,0-4.773-4.773h-38.2a4.77,4.77,0,0,0-4.773,4.773v71.618H119.365V211.736a4.773,4.773,0,0,0-4.775-4.773h-38.2a4.77,4.77,0,0,0-4.775,4.773v42.971H57.295V154.441a4.77,4.77,0,0,0-4.775-4.773h-38.2a4.773,4.773,0,0,0-4.775,4.773V254.707H4.775a4.775,4.775,0,1,0,0,9.55H186.208a4.775,4.775,0,1,0,0-9.55Zm-42.971-66.843h28.647v66.843H143.237Zm-62.07,28.647h28.647v38.2H81.167ZM19.1,159.216H47.746v95.491H19.1Zm0,0"
              transform="translate(0 -82.491)"
              stroke="#f2f2f2"
              stroke-width="3"
            />
          </g>
        </svg>
      ),
      image: "https://picsum.photos/800/600/?random",
      description:
        "Develop custom AI-powered applications for real-time poll, war room management, and audience engagement. Provision of secure data asset management, analysis, and reporting, empowering clients to make informed decisions based on real-time insights.",
    },
    {
      id: 7,
      title: "Real-time Trend Analysis",
      image: "https://picsum.photos/800/600/?random",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="222.334"
          height="181.434"
          viewBox="0 0 222.334 181.434"
        >
          <g id="growth-graph" transform="translate(-4 -10.916)">
            <path
              id="Path_5488"
              data-name="Path 5488"
              d="M222.363,165.679H212.208V20.688a4.145,4.145,0,0,0-3.97-4.3H181.765a4.145,4.145,0,0,0-3.97,4.3V165.679H158.986V56.486a4.145,4.145,0,0,0-3.97-4.3H128.539a4.145,4.145,0,0,0-3.97,4.3V165.679h-18.8V83.385a4.145,4.145,0,0,0-3.97-4.3H75.317a4.145,4.145,0,0,0-3.97,4.3v82.294H52.539V112.333a4.145,4.145,0,0,0-3.97-4.3H22.095a4.145,4.145,0,0,0-3.97,4.3v53.347H7.971a4.317,4.317,0,0,0,0,8.606c8.642-.011,214.392,0,214.392,0a4.317,4.317,0,0,0,0-8.606Zm-196.3,0V116.635H44.6v49.044Zm53.222,0V87.688H97.824v77.991Zm53.222,0V60.789h18.537v104.89Zm53.226,0V24.991h18.533V165.679Z"
              transform="translate(0 18.065)"
            />
            <path
              id="Path_5489"
              data-name="Path 5489"
              d="M12.644,117.7a4.288,4.288,0,0,0,3.042-1.261l60.26-60.258L84.09,78.5A4.333,4.333,0,0,0,91.2,80.041l52.665-53.672L141.959,40.98a4.3,4.3,0,1,0,8.53,1.113l3.44-26.321a4.33,4.33,0,0,0-4.6-4.846l-26.466,2.061a4.3,4.3,0,0,0,.664,8.58l14.07-1.1L89.837,69.148,81.716,46.9a4.331,4.331,0,0,0-7.084-1.567L9.6,110.358a4.333,4.333,0,0,0,3.042,7.345Z"
              transform="translate(14.478 0)"
            />
          </g>
        </svg>
      ),
      description:
        "Use AI and machine learning to track trends in media, social media, and on-ground activities in real time. Collection of data from multiple sources and analysis to identify patterns and sentiments, and provide custom insights and recommendations. Monitoring of competitors, stakeholders, and visualize real-time data to display valuable insights and trends.",
    },
  ];

  return (
    <section className="services">
      <div className="service-container">
        <h1 className="services-heading">Services </h1>
        <div className="services-description">
          We offer advanced socio-political research services to inform better
          decision making and policy making, upholding democracy and shaping the
          political discourse with our research-based insights.
        </div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.9 }}
          variants={{
            visible: { width: "100%", opacity: 1 },
            hidden: { width: "0%", opacity: 0 },
          }}
          className="learn-more-line"
        >
          <div className="service-carousel">
            <Swiper
              slidesPerView={4}
              spaceBetween={50}
              loop={true}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {ServicesData.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className="slider-contents">
                      <div className="services-image">
                        <div className="images-services"> {item.svg}</div>
                      </div>
                      <div className="services-content">
                        <div className="service-title">{item.title}</div>
                        <div className="service-descriptions">
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="service-carousel-mobile">
            <Swiper
              slidesPerView={1}
              spaceBetween={50}
              loop={true}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {ServicesData.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className="slider-contents">
                      <div className="services-image">
                        <div className="images-services"> {item.svg}</div>
                      </div>
                      <div className="services-content">
                        <div className="service-title">{item.title}</div>
                        <div className="service-descriptions">
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="service-carousel-tab">
            <Swiper
              slidesPerView={2}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {ServicesData.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <div className="slider-contents">
                      <div className="services-image">
                        <div className="images-services"> {item.svg}</div>
                      </div>
                      <div className="services-content">
                        <div className="service-title">{item.title}</div>
                        <div className="service-descriptions">
                          {item.description}
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </motion.div>
      </div>
    </section>
  );
};
